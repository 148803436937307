var LinkBuilder = /** @class */ (function () {
    function LinkBuilder() {
        this.initAnchorTags();
        this.initReloadButtons();
    }
    LinkBuilder.prototype.initAnchorTags = function () {
        var _this = this;
        var aTags = document.querySelectorAll("a");
        aTags.forEach(function (element) {
            element.addEventListener("click", _this.anchorTagClicked);
        });
    };
    LinkBuilder.prototype.anchorTagClicked = function (ev) {
        var _this = this;
        var anchorElement = this;
        ev.preventDefault();
        if (anchorElement.classList.contains("btn")) {
            // create loader
            if (!anchorElement.dataset["originalHTML"]) {
                anchorElement.dataset["originalHTML"] = anchorElement.innerHTML;
                document.addEventListener("visibilitychange", function () {
                    anchorElement.innerHTML = _this.dataset["originalHTML"];
                    anchorElement.style.height = "auto";
                });
            }
            var spanElement = anchorElement.querySelector(":scope > span");
            anchorElement.style.height = anchorElement.offsetHeight + "px";
            if (spanElement) {
                spanElement.innerHTML = '<i class="fad fa-spin fa-spinner-third"></i>';
            }
            else {
                anchorElement.innerHTML = '<i class="fad fa-spin fa-spinner-third"></i>';
            }
        }
        var url = anchorElement.getAttribute("href");
        if (url === "#") {
            if (anchorElement.id === "redirect-back") {
                history.back();
            }
        }
        else {
            window.location.assign(url);
        }
        return false;
    };
    LinkBuilder.prototype.initReloadButtons = function () {
        var _this = this;
        var reloadButtons = document.querySelectorAll("button[data-reload-button]");
        reloadButtons.forEach(function (element) {
            element.addEventListener("click", _this.reloadButtonClicked);
        });
    };
    LinkBuilder.prototype.reloadButtonClicked = function (ev) {
        ev.preventDefault();
        window.location.reload();
    };
    return LinkBuilder;
}());
window.addEventListener("load", function () {
    new LinkBuilder();
});
